//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";

export default {
  data() {
    return {
      //user: {},
      dialog_pwd: false,
      dialog_confirm: false,
      pwd_old: "",
      pwd_new: "",
      pwd_reap: "",
      valid: true,
      valid_pwd: true,
      old_Rules: [
        (v) => !!v || "Vecchia Password richiesta",
        () => this.valid_pwd == true || "Password Errata",
      ],
      new_Rules: [
        (v) => !!v || "Nuova Password richiesta",
        (v) => v.length > 7 || "Almeno 8 caratteri",
      ],
      reap_Rules: [(v) => v == this.pwd_new || "Deve essere uguale"],
      show_old: false,
      show_new: false,
      show_reap: false,
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.get_utente;
    },
  },
  mounted() {},
  methods: {
    change_notif_chat() {
      this.$store.dispatch("change_notif_chat", this.user.notif_chat);
    },
    change_notif_msg() {
      this.$store.dispatch("change_notif_msg", this.user.notif_msg);
    },
    change_notif_confirm() {
      this.$store.dispatch("change_notif_confirm", this.user.notif_confirm);
    },
    closeDialog() {
      this.pwd_old = "";
      this.pwd_new = "";
      this.pwd_reap = "";
      // this.$refs.form.reset();
      this.dialog_pwd = false;
    },
    verify() {
      this.loading = true;
      this.$refs.form.validate();
      if (!this.valid) {
        this.loading = false;
        return;
      }
      var url = process.env.VUE_APP_API_ROOT + "/token";
      var auth = {
        username: this.user.iva,
        password: this.pwd_old,
      };
      axios
        .get(url, {
          auth: auth,
        })
        .then((res) => {
          var url = process.env.VUE_APP_API_ROOT + "/set_new_password";
          var auth = {
            username: res.data.token,
            password: "",
          };
          var json = {
            iva: this.user.iva,
            new_pwd: this.pwd_new,
          };
          axios
            .post(url, json, {
              auth: auth,
            })
            .then(() => {
              this.loading = false;
              this.dialog_pwd = false;
              this.dialog_confirm = true;
              this.$refs.form.reset();
            });
        })
        .catch(() => {
          this.loading = false;
          this.valid_pwd = false;
          this.$refs.form.validate();
        });
    },
  },
};
